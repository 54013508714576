import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";
import HtmlControl from "../../core/HtmlControl";
import { XNode } from "../../core/XNode";

    styled.css `

    @keyframes marquee-text {
        100% {
            transform: translate(-100%, 0);
        }
    }
    `.installGlobal("");

    styled.css `

        white-space: nowrap;
        overflow: hidden;
        display: inline-block;

        & > .marquee {
            display: inline-block;
            padding-left: 100%;
            animation: marquee-text 15s linear infinite;
        }

    `.installGlobal("marquee-text");

class MarqueeText extends HtmlControl {

    prepare() {
        const text = this.textContent;
        this.textContent = "";
        // const root = this.attachShadow({ mode: "open"});

        XNode.render(this,<div>
            <div class="marquee">{text}</div>
        </div>);
       
    }


}

customElements.define("marquee-text", MarqueeText);